import { AxiosInstance, AxiosRequestConfig } from 'axios'

import { apiClient } from '../../../apiClient'
import {
  Category,
  CreateStore,
  CreateStoreResponse,
  EditCategoryResponse,
  EditSection,
  InventoryById,
  ManageCategory,
  ManageSectionResponse,
  ProductData,
  Response,
  Sessions,
  SlugResponse,
  StoreCard,
  StoreCardResponse,
  StoreListResponse,
} from './types'

class StoresApi {
  private api: AxiosInstance

  constructor(config: AxiosRequestConfig) {
    this.api = apiClient.create(config)
  }

  createStore = (payload: CreateStore) =>
    this.api.post<Response<CreateStoreResponse>>(
      `/gw1/puja/internal/v1/stores/create-store`,
      payload,
    )

  updateStore = (payload: CreateStore, store_id: string) =>
    this.api.put<Response<any>>(
      `/gw1/puja/internal/v1/stores/${store_id}/update-store`,
      payload,
    )

  getStoreById = (
    store_id: string,
    country_code?: string,
    language_code?: string,
  ) => {
    if (country_code && language_code) {
      return this.api.get<Response<any>>(
        `/gw1/puja/internal/v1/stores/${store_id}`,
        {
          headers: {
            'x-afb-country-code': country_code,
            'Accept-Language': language_code,
          },
        },
      )
    } else if (country_code) {
      return this.api.get<Response<any>>(
        `/gw1/puja/internal/v1/stores/${store_id}`,
        {
          headers: { 'x-afb-country-code': country_code },
        },
      )
    }
    return this.api.get<Response<any>>(
      `/gw1/puja/internal/v1/stores/${store_id}`,
    )
  }

  getSlugDuplicate = (slug: string) =>
    this.api.get<Response<SlugResponse>>(
      `/gw1/puja/internal/v1/stores/slug?slug=${slug}`,
    )

  createInventory = (storeId: string, payload: any) =>
    this.api.post<Response<any>>(
      `/gw1/puja/internal/v1/stores/${storeId}/add-product`,
      payload,
    )
  createFaqs = (payload: any) =>
    this.api.post<Response<any>>(
      `/gw1/puja/internal/v1/faq/create-faq`,
      payload,
    )

  updateFaqs = (payload: any) =>
    this.api.put<Response<any>>(`/gw1/puja/internal/v1/faq/update-faq`, payload)

  createStoreTime = (store_id: string, payload: Sessions) =>
    this.api.post<Response<any>>(
      `/gw1/puja/internal/v1/stores/${store_id}/store-sessions/bulk`,
      payload,
    )
  getStoreTime = (store_id: string) =>
    this.api.get<Response<Sessions>>(
      `/gw1/puja/internal/v1/stores/${store_id}/store-sessions`,
    )

  updateStoreTime = (store_id: string, payload: Sessions) =>
    this.api.put<Response<any>>(
      `/gw1/puja/internal/v1/stores/${store_id}/store-sessions/bulk`,
      payload,
    )

  getFaqs = (store_id: string) =>
    this.api.get<Response<any>>(
      `/gw1/puja/internal/v1/faq/faq-list?entity_id=${store_id}&faq_type=store`,
    )

  updateStoreStatus = (storeId: string, payload: any) => {
    return this.api.patch<Response<any>>(
      `/gw1/puja/internal/v1/stores/${storeId}/update-status`,
      payload,
    )
  }
  updatePujaStatus = (storeId: string, payload: any, country_code: string) => {
    return this.api.patch<Response<any>>(
      `/gw1/puja/internal/v1/stores/${storeId}`,
      payload,
      {
        headers: { 'x-afb-country-code': country_code },
      },
    )
  }
  updateStorePosition = (payload: any) =>
    this.api.put<Response<any>>(
      `/gw1/puja/internal/v1/stores/update-positions`,
      payload,
    )

  getStoreList = (
    pagination: any,
    country_code?: string,
    active?: boolean,
    lang?: string,
    sortBy?: 'created_at' | 'active' | 'status' | 'position',
    isArchive?: string | null,
    isActive?: string | null,
    serviceType?: string | null,
  ) => {
    const queryParams: { [key: string]: string | number | boolean } = {
      ...pagination,
    }
    if (active !== undefined) {
      queryParams.active = active
    }
    if (isArchive !== undefined && isArchive !== null) {
      queryParams.archived = isArchive === 'true'
    }
    if (isActive !== undefined && isActive !== null) {
      queryParams.active = isActive === 'true'
    }
    queryParams.sort_by = sortBy || 'created_at'
    if (serviceType !== undefined && serviceType !== null) {
      queryParams.service_type = serviceType || ''
    }
    const headers: any = {}
    if (lang) {
      headers['Accept-Language'] = lang
    }
    if (country_code) {
      headers['x-afb-country-code'] = country_code
    }
    return this.api.get<Response<StoreListResponse>>(
      '/gw1/puja/internal/v1/stores/store-list',
      {
        params: queryParams,
        headers,
      },
    )
  }

  cloneStore = (storeId: string, email: string, hubId: string) => {
    return this.api.post(`/gw1/puja/system/v1/stores/${storeId}/clone`, {
      email,
      hub_id: hubId,
    })
  }

  getProductList = (
    store_id: string,
    country_code?: string,
    sortBy?: 'price' | 'position' | 'created_at',
  ) => {
    const queryParams: any = {}
    if (sortBy) {
      queryParams['sort_by'] = sortBy
    }
    if (country_code) {
      return this.api.get<Response<ProductData>>(
        `/gw1/puja/internal/v1/stores/${store_id}/product-list?limit=200&offset=0`,
        {
          headers: { 'x-afb-country-code': country_code },
          params: queryParams,
        },
      )
    }
    return this.api.get<Response<ProductData>>(
      `/gw1/puja/internal/v1/stores/${store_id}/product-list?limit=200&offset=0`,
      {
        params: queryParams,
      },
    )
  }
  createInventoryItem = (inventory_id: string, payload: any) =>
    this.api.post<Response<any>>(
      `/gw1/puja/internal/v1/stores/${inventory_id}/add-product`,
      payload,
    )
  getInventoryById = (inventoryId: string) =>
    this.api.get<Response<InventoryById>>(
      `/gw1/puja/internal/v1/stores/inventory/${inventoryId}`,
    )

  archiveStore = (
    storeId: string,
    payload: {
      email?: string
      archive: boolean
    },
  ) => {
    return this.api.post<Response<any>>(
      `/gw1/puja/internal/v1/stores/${storeId}/archive`,
      payload,
    )
  }
  updateInventoryItem = (inventory_id: string, payload: any) =>
    this.api.put<Response<any>>(
      `/gw1/puja/internal/v1/stores/inventory/${inventory_id}/update-inventory`,
      payload,
    )
  deleteInventoryItem = (inventory_id: string) =>
    this.api.delete<Response<any>>(
      `/gw1/puja/internal/v1/stores/inventory/${inventory_id}/delete-inventory`,
    )
  getStoreCards = (pagination: any) =>
    this.api.get<Response<StoreCardResponse>>(
      `/gw1/puja/internal/v1/store-cards`,
      {
        params: pagination,
      },
    )

  getStoreCardById = (storeCardId: string) =>
    this.api.get<Response<StoreCard>>(
      `/gw1/puja/internal/v1/store-cards/${storeCardId}`,
    )

  createStoreCard = (storeCard: StoreCard) =>
    this.api.post('/gw1/puja/internal/v1/store-cards', storeCard)

  updateStoreCard = (storeCardId: string, storeCard: StoreCard) =>
    this.api.put(`/gw1/puja/internal/v1/store-cards/${storeCardId}`, storeCard)

  deleteStoreCard = (storeCardId: string) =>
    this.api.delete(`/gw1/puja/internal/v1/store-cards/${storeCardId}`)

  updateStatusStoreCard = (storeCardId: string, payload: any) =>
    this.api.patch(`/gw1/puja/internal/v1/store-cards/${storeCardId}`, payload)

  createCategory = (payload: any) =>
    this.api.post('/gw1/puja/internal/v1/categories', payload)

  getCategories = (pagination: any) =>
    this.api.get<Response<ManageCategory>>('/gw1/puja/internal/v1/categories', {
      params: pagination,
    })

  getCategoryById = (id: string) =>
    this.api.get<Response<EditCategoryResponse>>(
      `/gw1/puja/internal/v1/categories/${id}`,
    )
  updateCategory = (categoryId: string, category: Category) =>
    this.api.put(`/gw1/puja/internal/v1/categories/${categoryId}`, category)

  createSection = (payload: any) =>
    this.api.post('/gw1/puja/internal/v1/sections', payload)

  getSections = (pagination: any) =>
    this.api.get<Response<ManageSectionResponse>>(
      `/gw1/puja/internal/v1/sections`,
      {
        params: pagination,
      },
    )

  getSectionById = (id: string) =>
    this.api.get<Response<EditSection>>(`/gw1/puja/internal/v1/sections/${id}`)

  updateSection = (sectionId: string, section: any) =>
    this.api.put(`/gw1/puja/internal/v1/sections/${sectionId}`, section)
}

export default StoresApi
