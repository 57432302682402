import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  Col,
  Form, Input, InputNumber, Modal, Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip
} from 'antd'
import { CopyOutlined, PlusSquareOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { CreateStore, HubListItem } from '@a4b/api/src/modules/Monetisation/offerings/types'

interface Props {
  onCreateNew: () => void
  templeList: CreateStore[]
  onStatusChange: (item: CreateStore) => void
  triggerEdit: (temple: CreateStore) => void
  updatePosition: () => void
  enableUpdate: (status: boolean) => void
  formInstance: any
  initialValues: any
  enableEditPosition: boolean
  archiveStore: (storeId: string) => void
  unarchiveStore: (storeId: string) => void
  cloneStore: (storeId: string, hubId: string) => Promise<void>
  activeCloneId: string | undefined
  sortBy: 'created_at' | 'position',
  isArchive?: 'true' | 'false',
  isActive?: 'true' | 'false' | 'all',
  serviceType: 'pooja' | 'offerings' | 'promoter' | 'mandir_connect',
  updateFilters: (updateType: 'sortBy' | 'isArchive' | 'isActive' | 'serviceType', value?: 'true' | 'false' | 'created_at' | 'position' | 'pooja' | 'offerings' | 'promoter' | 'mandir_connect' | 'all') => void
  loading: boolean
  hubList: HubListItem[]
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const Label = styled.span`
  font-size: 14px;
  padding-left: 10px;
`
const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`
const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 100%;
`

enum MODAL_TYPE {
  CLONE = 'Clone',
  ARCHIVE = "Archive",
  UNARCHIVE = "Unarchive"
}
const ManageTemplesPage: React.FC<Props> = ({
  onCreateNew,
  templeList,
  onStatusChange,
  triggerEdit,
  formInstance,
  initialValues,
  updatePosition,
  enableEditPosition,
  enableUpdate,
  unarchiveStore,
  archiveStore,
  cloneStore,
  activeCloneId,
  sortBy,
  isArchive,
  isActive,
  serviceType,
  updateFilters,
  loading,
  hubList
}) => {
  const [dataSource, setDataSource] = useState(templeList);
  const [title, setTitle] = useState('')
  const [id, setId] = useState('')
  const [modelConfig, setModelConfig] = useState<{ type: MODAL_TYPE, id: string, hubId?: string, callback: (id: string, hubId: string) => void }>()

  useEffect(() => {
    setDataSource(templeList)
  }, [templeList])

  const filterSearchTitleInput = () => {
    return <Input
      placeholder="Search Title"
      value={title}
      onChange={e => {
        const currValue = e.target.value;
        setTitle(currValue);
        const filteredData = templeList.filter(entry =>
          entry.title.toLowerCase().includes(currValue.toLowerCase())
        );
        setDataSource(filteredData);
      }}
      allowClear
    />
  }
  const filterSearchIdInput = () => {
    return <Input
      placeholder="Search ID"
      value={id}
      onChange={e => {
        const currValue = e.target.value
        setId(currValue)
        const filteredData = templeList.filter(entry =>
          entry.id.toLowerCase().includes(currValue.toLowerCase())
        )
        setDataSource(filteredData)
      }}
      allowClear
    />
  }

  const dataColumns = [
    {
      title: <div style={{ display: 'flex', flexDirection: 'column' }}> <span>Title</span> <div>{filterSearchTitleInput()}</div></div>,
      dataIndex: 'title',
      key: 'no_of_items',
      width: '25%',
    },
    {
      title: <div style={{ display: 'flex', flexDirection: 'column' }}> <span>Store Id</span> <div>{filterSearchIdInput()}</div></div>,
      dataIndex: 'id',
      key: 'id',
      width: '15%',
      ellipsis: true,
      render: (id: string) => (
        <div>
          <Tooltip title="Copy Store ID">
            <span style={{ cursor: 'pointer', marginRight: '8px' }} onClick={() => navigator.clipboard.writeText(id)}>
              <CopyOutlined />
            </span>
          </Tooltip>
          <Tooltip placement="topLeft" title={id}>
            {id}
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'No of Items',
      dataIndex: 'no_of_items',
      key: 'no_of_items',
      width: '8%',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (text: string, record: any) => {
        return (
          <span>
            <Label>{record.active ? <Tag color='green'>Active</Tag> : <Tag color='red'>In Active</Tag>}</Label>
          </span>
        )
      },
    },
    {
      title: 'Service type',
      dataIndex: 'service_type',
      key: 'service_type',
      width: '12%',
      render: (text: string) => <Tag color='blue'>{text}</Tag>,
    },
    {
      title: 'Store type',
      dataIndex: 'store_type',
      key: 'store_type',
      width: '14%',
      render: (text: string, record: any) => {
        const currentDate = new Date().getTime()
        const processDate = new Date(record.process_date).getTime()

        let displayStoreType = text;

        if (processDate > currentDate) {
          if (text === 'regular') {
            displayStoreType = 'preBooking';
          } else if (text === 'mixed') {
            displayStoreType = 'mixed-preBooking';
          }
        }

        return <Tag color='purple'>{displayStoreType}</Tag>;
      },
    },
    {
      title: 'Position',
      key: 'position',
      width: '10%',
      render: (text: string, record: any) => {
        return enableEditPosition ? (
          <Form.Item
            noStyle={true}
            name={record.id}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 12 }}
            rules={[
              {
                required: true,
                message: 'Please input position!',
              },
            ]}
          >
            <InputNumber placeholder='Enter position' />
          </Form.Item>
        ) : (
          <span> {`${record?.position}`}</span>
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: '30%',
      render: (text: any, record: any, index: any) => {
        return (
          <Row >
            <Col span={6}>
              <Switch

                checked={record.active}
                onChange={() => onStatusChange(record)}
              />
            </Col>
            <Col span={6}>
              <Link
                to={`/monetisation/offerings/temple/create?edit=true&store_id=${record.id}`}
              >
                <Button type='link'>Edit</Button>
              </Link>
            </Col>

            <Col span={6}>
              {
                record.archived ?
                  <Button onClick={() => setModelConfig({ type: MODAL_TYPE.UNARCHIVE, id: record.id, hubId: undefined, callback: unarchiveStore })} type='link'> Unarchive </Button>
                  :
                  <Button onClick={() => setModelConfig({ type: MODAL_TYPE.ARCHIVE, id: record.id, hubId: undefined, callback: archiveStore })} type='link' > Archive </Button>
              }
            </Col>
            <Col span={6}>
              <Button onClick={() => setModelConfig({ type: MODAL_TYPE.CLONE, id: record.id, hubId: '', callback: cloneStore })} loading={activeCloneId === record.id} > Clone </Button>
            </Col>
          </Row>
        )
      },
    },
  ]

  return (
    <Container
      title='Manage Stores'
      style={{ width: '100%' }}
      extra={
        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
          <Col>
            <label>Filter by: </label>
            <Select value={serviceType} onChange={(value) => { updateFilters('serviceType', value) }} style={{ width: '150px' }}>
              <Select.Option value="offerings">Offerings</Select.Option>
              <Select.Option value="pooja">Pooja</Select.Option>
              <Select.Option value="promoter">Promoter</Select.Option>
              <Select.Option value="mandir_connect">Mandir Connect</Select.Option>
            </Select>
            <Select placeholder="Is archived" allowClear value={isArchive} onChange={(value) => { updateFilters('isArchive', value) }} style={{ width: '120px', paddingLeft: '10px' }}>
              <Select.Option value="true">Archived</Select.Option>
              <Select.Option value="false">Unarchived</Select.Option>
            </Select>
            <Select placeholder="Status" value={isActive} onChange={(value) => { updateFilters('isActive', value) }} style={{ width: '120px', paddingLeft: '10px' }}>
              <Select.Option value="true">Active</Select.Option>
              <Select.Option value="false">Inactive</Select.Option>
              <Select.Option value="all">All</Select.Option>
            </Select>
          </Col>
          <Col>
            <label style={{ paddingLeft: '10px' }}>Sort by: </label>
            <Select value={sortBy} onChange={(value) => { updateFilters('sortBy', value) }} style={{ width: '120px' }}>
              <Select.Option value="created_at">Created at</Select.Option>
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="position">Position</Select.Option>
            </Select>
          </Col>
          <Col style={{ paddingLeft: '10px' }}>
            {enableEditPosition ? (
              <Button type='default' onClick={() => enableUpdate(false)}>
                Cancel Edit Position
              </Button>
            ) : (
              <Button type='primary' onClick={() => enableUpdate(true)}>
                Edit Position
              </Button>
            )}
          </Col>
          {enableEditPosition && <Col style={{ paddingLeft: '10px' }}>
            <Button type='primary' onClick={updatePosition}>
              Update Position
            </Button>
          </Col>}
          <Col style={{ paddingLeft: '10px' }}>
            <Button type='primary'>
              <Link to='/monetisation/offerings/temple/create'>
                <PlusSquareOutlined /> Add new Store
              </Link>
            </Button>
          </Col>
        </Row>
      }
    >
      <Space direction={'vertical'} style={{ width: '100%' }}></Space>
      {
        modelConfig &&
        <Modal
          title={modelConfig.type}
          visible={true}
          onOk={() => {
            modelConfig.callback(modelConfig.id, modelConfig.hubId || '')
            setModelConfig(undefined)
          }}
          onCancel={() => { setModelConfig(undefined) }}

        >

          <p style={{ textAlign: "center" }}>
            <b>
              {
                modelConfig.type === MODAL_TYPE.CLONE ?
                  "Are you sure you want to Clone ?" : modelConfig.type === MODAL_TYPE.ARCHIVE ?
                    "Are you sure you want to Archive ?" :
                    "Are you sure you want to Unarchive ?"
              }
            </b></p>
          {
            modelConfig.hubId !== undefined &&
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              <p>
                Please choose the hub ,for which you want to clone this store
              </p>
              <Select onChange={(id) => setModelConfig({ ...modelConfig, hubId: id })} aria-required style={{ width: '350px' }} placeholder="Select hub" >
                {
                  hubList.map((hub: HubListItem) => (
                    <Select.Option key={hub.id} value={hub.id}>{hub.name}</Select.Option>
                  ))
                }
              </Select>
            </div>
          }
        </Modal>
      }
      <BodyContainer>
        {/* {templeList.map((temple: ManageTemple, index: number) => (
          <ManageTempleCard
            temple={temple}
            onStatusChange={onStatusChange}
            triggerEdit={triggerEdit}
            key={index}
          />
        ))} */}
      </BodyContainer>
      <Row>
        <Col span={28}>
          <Form form={formInstance} initialValues={initialValues}>
            <Table
              sticky={true}
              rowKey={(record: CreateStore) => record?.id}
              columns={dataColumns}
              dataSource={dataSource}
              pagination={false}
              loading={loading}
            />
          </Form>
        </Col>
      </Row>
      <LabelRightAlign>
        {/* <Pagination defaultPageSize={5} defaultCurrent={1} total={30} /> */}
      </LabelRightAlign>
    </Container>
  )
}

export default ManageTemplesPage