// JourneyDetails.tsx
import React from 'react';
import { Form, Input, Row, Card, Divider, Typography } from 'antd';
import styled from 'styled-components';
import S3Upload from '@a4b/ui/src/modules/monetisation/puja/molecules/S3Upload';
import { PUJA_PRESIGN_URL, YODA_CDN_URL } from '@/utils/constants';
import ColorProperties from '../../../components/inputs/ColorProperties';
import CompletedCard from './CompletedCard';
import { FormInstance } from 'antd/es/form';
import InputColorPreview from '../../../components/inputs/InputColorPreview';
import MediaProperties from '../../../components/inputs/MediaProperties';

const Block = styled(Card)`
  background: #f5f5f5;
  padding: 5px;

  border-radius: 10px;
  margin-bottom: 10px;
`

const Subtitle = styled(Typography.Title)`
  color: #8c8c8c !important;
`

interface JourneyDetailsProps {
    formInstance: FormInstance
}

const JourneyDetails: React.FC<JourneyDetailsProps> = ({ formInstance }) => {
    return (
        <div>
            {/* Background section */}
            <Form.Item
                label={<Subtitle level={5}>Background color</Subtitle>}>
                <Block>
                    <ColorProperties formInstance={formInstance} fieldPath={['journey_details', 'bg_color']} />
                    <Form.Item
                        label='Background Image'
                        name={['journey_details', 'bg_image']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: 'Please upload background image URL!' }]}
                        valuePropName='fileList'
                    >
                        <S3Upload
                            cdnUrl={YODA_CDN_URL}
                            preSignUrl={PUJA_PRESIGN_URL}
                            min={1}
                            max={1}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Title Background Color'
                        name={['journey_details', 'title_bg_color']}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: 'Please enter title background color' }]}
                    >
                        <Input placeholder='Enter title background color' />
                    </Form.Item>
                </Block>
            </Form.Item>

            <Divider />

            {/* Onboarding data */}
            <Row justify='space-between' style={{ display: 'flex' }}>
                <div style={{ flexBasis: '48%' }}>
                    <Form.Item
                        label='Onboarding data(Hindi)'>
                        <Block>
                            <Form.Item
                                label='Description'
                                name={['journey_details', 'onboarding_data', 'media', 'description']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'Please enter Description' }]}
                            >
                                <Input placeholder='Enter Description' />
                            </Form.Item>
                            <MediaProperties fieldPath={['journey_details', 'onboarding_data', 'media']} />
                            <Form.Item
                                label='CTA text'
                                name={['journey_details', 'onboarding_data', 'cta_text']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'Please enter CTA text' }]}
                            >
                                <Input placeholder='Enter CTA text' />
                            </Form.Item>
                        </Block>
                    </Form.Item>
                </div>
                <div style={{ flexBasis: '48%' }}>
                    <Form.Item
                        label='Onboarding data(English)'>
                        <Block>
                            <Form.Item
                                label='Description'
                                name={['journey_details', 'onboarding_data_en', 'media', 'description']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'Please enter Description' }]}
                            >
                                <Input placeholder='Enter Description' />
                            </Form.Item>
                            <MediaProperties fieldPath={['journey_details', 'onboarding_data_en', 'media']} />
                            <Form.Item
                                label='CTA text'
                                name={['journey_details', 'onboarding_data_en', 'cta_text']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'Please enter CTA text' }]}
                            >
                                <Input placeholder='Enter CTA text' />
                            </Form.Item>
                        </Block>
                    </Form.Item>
                </div>
            </Row>

            <Divider />

            {/* Subtitle */}
            <Block>
                <Row justify='space-between' style={{ display: 'flex' }}>
                    <div style={{ flexBasis: '48%' }}>
                        <Form.Item
                            label='Subtitle'
                            name={['journey_details', 'subtitle']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ required: true, message: 'Please enter subtitle' }]}
                        >
                            <Input placeholder='Enter subtitle' />
                        </Form.Item>
                    </div>
                    <div style={{ flexBasis: '48%' }}>
                        <Form.Item
                            label='Subtitle (English)'
                            name={['journey_details', 'subtitle_en']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ required: true, message: 'Please enter subtitle(en)' }]}
                        >
                            <Input placeholder='Enter subtitle in English' />
                        </Form.Item>
                    </div>
                </Row>

                <Row justify='space-between' style={{ display: 'flex' }}>
                    <div style={{ flexBasis: '48%' }}>
                        <Form.Item
                            label='Popup Description'
                            name={['journey_details', 'popup_description']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ required: true, message: 'Please enter popup description' }]}
                        >
                            <Input.TextArea placeholder='Enter popup description' />
                        </Form.Item>
                    </div>
                    <div style={{ flexBasis: '48%' }}>
                        <Form.Item
                            label='Popup Description (English)'
                            name={['journey_details', 'popup_description_en']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ required: true, message: 'Please enter popup description(en)' }]}
                        >
                            <Input.TextArea placeholder='Enter popup description in English' />
                        </Form.Item>
                    </div>
                </Row>

                <Row justify='space-between' style={{ display: 'flex' }}>
                    <div style={{ flexBasis: '48%' }}>
                        <Form.Item
                            label='Journey Page CTA'
                            name={['journey_details', 'journey_page_cta']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ required: true, message: 'Please enter  journey page CTA' }]}
                        >
                            <Input placeholder='Enter journey page CTA' />
                        </Form.Item>
                    </div>
                    <div style={{ flexBasis: '48%' }}>
                        <Form.Item
                            label='Journey Page CTA (English)'
                            name={['journey_details', 'journey_page_cta_en']}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ required: true, message: 'Please enter  journey page CTA(en)' }]}
                        >
                            <Input placeholder='Enter journey page CTA in English' />
                        </Form.Item>
                    </div>
                </Row>
            </Block>

            <Divider />

            {/* Banner data */}
            <Form.Item
                label={<Subtitle level={5}>Banner data</Subtitle>}>
                <Block>
                    <Row justify='space-between' style={{ display: 'flex' }}>
                        <div style={{ flexBasis: '48%' }}>
                            <Form.Item
                                label='Banner Starting Title'
                                name={['journey_details', 'banner_data', 'starting_title']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'Please enter banner starting title' }]}
                            >
                                <Input placeholder='Enter banner starting title' />
                            </Form.Item>
                        </div>
                        <div style={{ flexBasis: '48%' }}>
                            <Form.Item
                                label='Banner Starting Title (English)'
                                name={['journey_details', 'banner_data', 'starting_title_en']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'Please enter banner starting title(en)' }]}
                            >
                                <Input placeholder='Enter banner starting title in English' />
                            </Form.Item>
                        </div>
                    </Row>
                    <Form.Item
                        label='Title text color'>
                        <ColorProperties formInstance={formInstance} fieldPath={['journey_details', 'banner_data', 'title_text_color']} />
                    </Form.Item>


                    <Row justify='space-between' style={{ display: 'flex' }}>
                        <div style={{ flexBasis: '48%' }}>
                            <Form.Item
                                label='Banner Starting CTA'
                                name={['journey_details', 'banner_data', 'starting_cta']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'Please enter banner starting CTA' }]}
                            >
                                <Input placeholder='Enter banner starting CTA' />
                            </Form.Item>
                        </div>
                        <div style={{ flexBasis: '48%' }}>
                            <Form.Item
                                label='Banner Starting CTA (English)'
                                name={['journey_details', 'banner_data', 'starting_cta_en']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'Please enter banner starting CTA(en)' }]}
                            >
                                <Input placeholder='Enter banner starting CTA in English' />
                            </Form.Item>
                        </div>
                    </Row>

                    <Form.Item
                        label='Background color'>
                        <ColorProperties formInstance={formInstance} fieldPath={['journey_details', 'banner_data', 'bg_color']} />
                    </Form.Item>

                    <Form.Item
                        label='Completed background color'>
                        <ColorProperties formInstance={formInstance} fieldPath={['journey_details', 'banner_data', 'completed_bg_color']} />
                    </Form.Item>

                    <Form.Item
                        label='Media'>
                        <MediaProperties fieldPath={['journey_details', 'banner_data', 'media']} />
                    </Form.Item>
                </Block>

            </Form.Item>

            <Divider />

            {/* Card */}
            <Form.Item
                label={<Subtitle level={5}>Card Attributes</Subtitle>}>

                <Block>
                    <Form.Item
                        label='Background color'>
                        <ColorProperties formInstance={formInstance} fieldPath={['journey_details', 'card_attr', 'bg_color']} />
                    </Form.Item>
                    <Form.Item
                        label='Completed background color'>
                        <ColorProperties formInstance={formInstance} fieldPath={['journey_details', 'card_attr', 'completed_bg_color']} />
                    </Form.Item>
                    <Form.Item
                        label='Completed media'>
                        <Form.Item
                            label='Media'>
                            <MediaProperties fieldPath={['journey_details', 'card_attr', 'completed_media']} />
                        </Form.Item>
                    </Form.Item>
                </Block>

            </Form.Item>

            <Divider />

            {/* Completed card */}
            <Form.Item
                label={<Subtitle level={5}>Completed Card</Subtitle>}>
                <Block>
                    <Row justify='space-between' style={{ display: 'flex' }}>
                        <div style={{ flexBasis: '48%' }}>
                            <Form.Item
                                label='Completed Card(Hindi)'>
                                <CompletedCard fieldPath={['journey_details', 'completed_card']} />
                            </Form.Item>
                        </div>
                        <div style={{ flexBasis: '48%' }}>
                            <Form.Item
                                label='Completed Card(English)'>
                                <CompletedCard fieldPath={['journey_details', 'completed_card_en']} />
                            </Form.Item>
                        </div>
                    </Row>
                </Block>
            </Form.Item>

            <Divider />

            {/* Common CTA Background */}
            <Form.Item
                label={<Subtitle level={5}>Common CTA BG Color</Subtitle>}>
                <Block>
                    <InputColorPreview fieldPath={['journey_details', 'common_cta_bg_color']} formInstance={formInstance} />
                </Block>
            </Form.Item>
        </div >

    );
};

export default JourneyDetails;
