import { AxiosRequestConfig } from 'axios'

import BatchApi from './BatchApi'
import CampaignsApi from './Campaigns'
import CategoriesApi from './Categories'
import FaqsApi from './FaqsApi'
import FiltersApi from './Filters'
import HubApi from './Hub'
import OrdersApi from './Orders'
import PageInfoApi from './PageInfo'
import Product from './Product'
import PujaFiltersApi from './PujaFilters'
import RescheduleApi from './Reschedule'
import ReviewApi from './ReviewApi'
import SchedulerApi from './Scheduler'
import StepsApi from './StepsApi'
import StoresApi from './StoresApi'
import SystemApi from './System'
import TutorialApi from './Tutorials'
import UserSegmentsApi from './UserSegmentsApi'

class MonetisationPujaApi {
  hub: HubApi
  storesApi: StoresApi
  productApi: Product
  pageInfoApi: PageInfoApi
  tutorialApi: TutorialApi
  faqsApi: FaqsApi
  stepsApi: StepsApi
  batchApi: BatchApi
  reviewApi: ReviewApi
  orderApi: OrdersApi
  systemApi: SystemApi
  campaignsAPi: CampaignsApi
  userSegmentsApi: UserSegmentsApi
  schedulerApi: SchedulerApi
  categoriesApi: CategoriesApi
  filtersApi: FiltersApi
  pujaFiltersApi: PujaFiltersApi
  rescheduleApi: RescheduleApi

  constructor(
    config: AxiosRequestConfig,
    monetisationExtraHeaders?: { 'Accept-Language': string },
    appHeaders?: { 'x-afb-app-name': string },
  ) {
    const headers = {
      ...config.headers,
      ...(monetisationExtraHeaders || {}),
      ...(appHeaders || {}),
    }
    const updatedConfig = { ...config, headers }
    this.hub = new HubApi(updatedConfig)
    this.storesApi = new StoresApi(updatedConfig)
    this.productApi = new Product(updatedConfig)
    this.pageInfoApi = new PageInfoApi(updatedConfig)
    this.tutorialApi = new TutorialApi(updatedConfig)
    this.faqsApi = new FaqsApi(updatedConfig)
    this.stepsApi = new StepsApi(updatedConfig)
    this.batchApi = new BatchApi(updatedConfig)
    this.reviewApi = new ReviewApi(updatedConfig)
    this.orderApi = new OrdersApi(updatedConfig)
    this.systemApi = new SystemApi(updatedConfig)
    this.campaignsAPi = new CampaignsApi(updatedConfig)
    this.userSegmentsApi = new UserSegmentsApi(updatedConfig)
    this.schedulerApi = new SchedulerApi(updatedConfig)
    this.categoriesApi = new CategoriesApi(updatedConfig)
    this.filtersApi = new FiltersApi(updatedConfig)
    this.pujaFiltersApi = new PujaFiltersApi(updatedConfig)
    this.rescheduleApi = new RescheduleApi(updatedConfig)
  }
}

export default MonetisationPujaApi
