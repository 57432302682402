import React from 'react'
import styled from 'styled-components'
import { Card, Col, Row, Space, Steps } from 'antd'

const { Step } = Steps
interface Props {
    currentStep: number
    steps: any[]
    title: string
    direction?: 'vertical' | 'horizontal' | undefined
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
  }
`
const OverrideSteps = styled(Steps)`
  display: flex;
  max-width: 80%;
  justify-content: center;  
  && .ant-steps-item-description {
    max-width: 250px;
  }
  padding-bottom: 20px;
`
const Stepper: React.FC<Props> = ({
    currentStep,
    steps,
    title,
    direction = 'vertical'
}) => {
    return (
        <Container title={title} style={{ width: '100%' }}>
            <Space direction={direction} style={{ width: '100%' }}></Space>
            <Row justify='center' style={{ width: '100%' }}>
                <OverrideSteps
                    style={{ paddingLeft: 10, paddingRight: 10 }}
                    current={currentStep}
                // type="navigation"
                // className="site-navigation-steps"
                >
                    {steps?.map(item => (
                        <Step
                            key={item.title}
                            title={item.title}
                            description={item.description}
                        />
                    ))}
                </OverrideSteps>
            </Row>
            <Row>
                <Col span={24}>{steps[currentStep].content}</Col>
            </Row>
        </Container>
    )
}

export default Stepper
