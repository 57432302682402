import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { YODA_CDN_URL, YODA_PRESIGN_URL } from '@/utils/constants'

import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Space, Modal, Divider, Switch, Spin } from 'antd'
import styled from 'styled-components'

import S3Upload, { AllowedFormats } from '@a4b/ui/src/modules/monetisation/molecules/S3Upload'

import { HubList, HubListItem, TemplesList, TempleData } from '@a4b/api/src/modules/Monetisation/Offerings/types'
import { CopyOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import moment, { Moment } from 'moment-timezone';

const weekdays = [
  { name: 'Sunday', value: 0 },
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 }
];

const godNames = [
  { displayName: "Lord Surya Dev", value: "lord_surya_dev" },
  { displayName: "Lord Shiva", value: "lord_shiva" },
  { displayName: "Lord Hanuman", value: "lord_hanuman" },
  { displayName: "Lord Ganesh", value: "lord_ganesh" },
  { displayName: "Lord Vishnu", value: "lord_vishnu" },
  { displayName: "Mata Durga", value: "mata_durga" },
  { displayName: "Lord Shani Dev", value: "lord_shani_dev" }
];


const { Option } = Select
const { TextArea } = Input
interface Props {
  hubList: HubList | undefined
  form: any
  currentStep: number
  isEditJourney: boolean
  slugState: boolean
  nextHandler: () => void
  previousHandler: () => void
  submitFormHandler: () => void
  onValuesChange: (values: any) => void
  handleCloneModal: () => void
  acceptLanguage: string
  isLoading: boolean
  locationDetails: any
  onRemoveAddress: (removed: boolean) => void
  templesList: TemplesList | undefined
}

const LabelRightAlign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 10px;
`
const tags: any = {
  en: ['Festival', 'Kundali dosha', 'Business & Finance', 'Marriage & Love', 'Career'],
  hi: ['त्योहार', 'कुंडली दोष', 'धन एवं व्यापार', 'प्यार एवं विवाह', 'करियर']
}

const TempleCreation: React.FC<Props> = ({
  hubList,
  form,
  previousHandler,
  nextHandler,
  submitFormHandler,
  currentStep,
  isEditJourney,
  onValuesChange,
  slugState,
  handleCloneModal,
  acceptLanguage,
  isLoading,
  locationDetails,
  onRemoveAddress,
  templesList
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [visibleCta, setVisibleCta] = useState<boolean>(false)
  const [checked, setChecked] = useState<any>(false)
  const [requiredLocationDetails, setRequiredLocationDetails] = useState<boolean>(false)
  const [visibleGodDetails, setVisibleGodDetails] = useState<any>(false)
  const [visibleSubscriptionDetails, setVisibleSubscriptionDetails] = useState<boolean>(false)

  const navigate = useNavigate()

  const [data, setData] = useState(false)

  useEffect(() => {
    if (locationDetails?.delivery_pickup_address && Object.keys(locationDetails?.delivery_pickup_address).length > 0)
      setData(true)
    else
      setData(false)
  }, [locationDetails])

  const showModal = () => {
    setVisible(true);
  }

  const showModalGodDetails = () => {
    setVisibleGodDetails(true);
  }

  const showModalCta = () => {
    setVisibleCta(true);
  }

  const handleCtaOk = () => {
    setVisibleCta(false);
  }

  const handleGodDetailsOk = () => {
    setVisibleGodDetails(false);
  }

  const handleCtaCancel = () => {
    setVisibleCta(false);
  }

  const handleGodDetailsCancel = () => {
    setVisibleGodDetails(false);
  }

  const handleOk = () => {
    setVisible(false);
  }

  const handleCancel = () => {
    setVisible(false);
  }

  const showModalSubscriptionDetails = () => {
    setVisibleSubscriptionDetails(true);
  }

  const handleSubscriptionDetailsOk = () => {
    setVisibleSubscriptionDetails(false);
  }

  const handleSubscriptionDetailsCancel = () => {
    setVisibleSubscriptionDetails(false);
  }

  const disabledDate = (current: Moment | null): boolean => {
    const currentMoment = moment.tz("Asia/Kolkata")
    return current ? current < currentMoment.startOf('day') : false;
  }

  useEffect(() => {
    const checkToggle: boolean = form?.getFieldsValue('location_details')?.location_details
    setChecked(checkToggle);
  }, [])

  const defaultPickupInfo = {
    phone: '9036118316',
    address: '435,1st Floor, 17th Cross road, 19th Main Rd, ',
    pincode: '560102',
    landmark: 'above Axis Bank, Sector 4, HSR Layout, ',
    district: 'Bangalore',
    city: 'Bangalore',
    state: 'Karnataka',
    country: 'India'
  };

  const initialValues: any = {
    location_details: {
      delivery_pickup_address: {
        ...defaultPickupInfo,
      },
    }
  };


  const handleAddClick = () => {
    setData(true)
  };

  const handleRemoveClick = () => {
    setData(false)
    onRemoveAddress(true);
  };

  const handleCheckboxChange = () => {
    const values = form.getFieldsValue(['android', 'ios', 'web']);
    form.setFieldsValue({
      all: values.android && values.ios && values.web,
    });
  };

  const handleCheckAllPlatforms = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    form.setFieldsValue({
      android: checked,
      ios: checked,
      web: checked,
    });
  };

  return (
    <Row>
      {
        isLoading ?
          <Row style={{ minHeight: '70vh', display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Spin />
          </Row>
          :
          <>
            <Form
              form={form}
              layout='vertical'
              name='create store'
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onValuesChange={onValuesChange}
            >
              <Col span={24}>
                <Row>
                  <Col span={8}>
                    <Form.Item
                      label='Store Id'
                      name='store_id'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input style={{ color: 'black' }} disabled></Input>
                    </Form.Item>
                    <Form.Item
                      label='Name of temple'
                      name='title'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        { required: true, message: 'Please input name of the temple!' },
                      ]}
                    >
                      <Input placeholder='Enter temple name' />
                    </Form.Item>
                    <Form.Item
                      label='Display Title'
                      name='display_title'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        { required: false },
                      ]}
                    >
                      <Input placeholder='Enter display title' />
                    </Form.Item>
                    <Form.Item
                      label='Sub Title'
                      name='sub_title'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input subtitle of the temple!',
                        },
                      ]}
                    >
                      <Input placeholder='Enter temple subtitle' />
                    </Form.Item>
                    <Form.Item
                      label='Short Description'
                      name='short_description'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        { required: true, message: 'Please input short description!' },
                      ]}
                    >
                      <TextArea placeholder='Enter short description' />
                    </Form.Item>
                    <Form.Item
                      label='Long Description'
                      name='long_description'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        { required: true, message: 'Please input long description!' },
                      ]}
                    >
                      <TextArea placeholder='Enter long description' />
                    </Form.Item>

                    <Form.Item
                      label='Select Hub'
                      name='hub_id'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: true, message: 'Please select hub name!' }]}
                    >
                      <Select placeholder='Select hub name'>
                        {hubList?.hubs?.map((hub: HubListItem) => (
                          <Option value={hub.id} key={hub.id}>
                            {hub.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label='Select Temple'
                      name='temple_id'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: false, message: 'Please select temple name!' }]}
                    >
                      <Select placeholder='Select temple name'>
                        {templesList?.temples_list?.map((temple: TempleData) => (
                          <Option value={temple.temple_id} key={temple.temple_id}>
                            {temple.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Row justify='space-between'>
                      <Form.Item
                        label={`CTA`}
                        name='cta'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            required: false,
                            message: 'Please enter CTA details',
                          },
                        ]}
                      >
                        <Button style={{ width: 100, background: '#1890ff', color: 'white' }} onClick={showModalCta}>
                          CTA
                        </Button>
                        <Modal
                          title="CTA"
                          visible={visibleCta}
                          onOk={handleCtaOk}
                          onCancel={handleCtaCancel}
                          width="45vw"
                          style={{ marginTop: '-95px' }}
                        >
                          <Row>
                            <Col span={12}>
                              <Form.Item
                                label='Cta'
                                name={['cta', 'text']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: true, message: 'Please input cta text!' }]}
                              >
                                <Input placeholder='Enter Cta text' />
                              </Form.Item>

                              <Form.Item
                                label="Media type"
                                name={['cta', 'media', 'media_type']}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                  {
                                    required: false,
                                    message: 'Please select cta media type!',
                                  },
                                ]}
                              >
                                <Select placeholder='Type'>
                                  <Option value='lottie'>GIF</Option>
                                  <Option value='image'>Image</Option>
                                </Select>
                              </Form.Item>

                              <Form.Item
                                name={['cta', 'media', 'media_url']}
                                label="Media url"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                  {
                                    min: 0,
                                    max: 1,
                                    validator: async (rules: any, values: any) => {
                                      if (values?.length < rules.min) {
                                        return Promise.reject('Please upload Cta media Image')
                                      }
                                    },
                                  },
                                ]}
                                valuePropName='fileList'
                              >
                                <S3Upload
                                  cdnUrl={YODA_CDN_URL}
                                  preSignUrl={YODA_PRESIGN_URL}
                                  min={1}
                                  max={1}
                                />
                              </Form.Item>

                              <Form.Item
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                label="Visible"
                                name={['cta', 'media', 'visible']}
                                valuePropName="checked"
                              >
                                <Switch />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Modal>
                      </Form.Item>
                      <Form.Item
                        label={`Icon URL`}
                        name='icon_url'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            min: 0,
                            max: 1,
                            validator: async (rules: any, values: any) => {
                              if (values?.length < rules.min) {
                                return Promise.reject('Please upload icon')
                              }
                            },
                            required: true
                          },
                        ]}
                        valuePropName='fileList'
                      >
                        <S3Upload cdnUrl={YODA_CDN_URL} preSignUrl={YODA_PRESIGN_URL} min={0} max={1} />
                      </Form.Item>
                    </Row>


                    {/* <Row style={{ display: 'flex', justifyContent: 'space-between' }}> */}
                    <Form.Item
                      label='Cover Media'
                      style={{ marginBottom: '0px' }}
                    >
                      <div style={{
                        border: '1px solid #e0dcdc',
                        marginRight: '0px',
                        marginBottom: '3px',
                        padding: '10px',
                      }} title='Order Intro Media'>
                        <Row justify="space-between" style={{ width: '100%' }}>
                          <Form.Item
                            label={`Cover Media type`}
                            name='coverMediaType'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                              {
                                required: true,
                                message: 'Please select cover media type!',
                              },
                            ]}
                            style={{}}
                          >
                            <Select style={{ width: 100 }} placeholder='Type'>
                              <Option value='lottie'>Lottie</Option>
                              <Option value='image'>Image</Option>
                              <Option value='video'>Video</Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            label={`Cover Media`}
                            name='media_url'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                              {
                                min: 1,
                                max: 1,
                                validator: async (rules: any, values: any) => {
                                  if (values?.length < rules.min) {
                                    return Promise.reject('Please upload Cover media Images')
                                  }
                                },
                              },
                            ]}
                            valuePropName='fileList'
                          >
                            <S3Upload
                              cdnUrl={YODA_CDN_URL}
                              preSignUrl={YODA_PRESIGN_URL}
                              min={1}
                              max={1}
                            />
                          </Form.Item>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues.coverMediaType !== currentValues.coverMediaType
                            }
                          >
                            {({ getFieldValue }) => (
                              getFieldValue("coverMediaType") === "video" &&
                              <Form.Item
                                label={`Thumbnail`}
                                name='thumbnail'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                  {
                                    min: 1,
                                    max: 1,
                                    validator: async (rules: any, values: any) => {
                                      if (values?.length < rules.min) {
                                        return Promise.reject('Please upload Thumbnail Image')
                                      }
                                    },
                                  },
                                ]}
                                valuePropName='fileList'
                              >
                                <S3Upload
                                  cdnUrl={YODA_CDN_URL}
                                  preSignUrl={YODA_PRESIGN_URL}
                                  min={0}
                                  max={1}
                                />
                              </Form.Item>
                            )}
                          </Form.Item>
                        </Row>
                      </div>
                    </Form.Item>

                    <Form.Item
                      label='Order Intro Media'
                      style={{ marginTop: '10px' }}
                    >
                      <div style={{
                        border: '1px solid #e0dcdc',
                        marginRight: '0px',
                        marginBottom: '3px',
                        padding: '10px',
                      }} title='Order Intro Media'>
                        <Row justify="space-between" style={{ width: '100%' }}>
                          <Form.Item
                            label='Media type'
                            name={['order_intro_media', 'media_type']}
                            labelCol={{ span: 28 }}
                            wrapperCol={{ span: 28 }}
                            style={{ width: '50%' }}
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (getFieldValue('service_type') === 'mandir_connect' && !value) {
                                    return Promise.reject('Enter media type');
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Select placeholder="Please Select Media type">
                              <Option value={'video'} key={'video'}>
                                Video
                              </Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            label={`Media`}
                            name={['order_intro_media', 'media_url']}
                            labelCol={{ span: 28 }}
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    getFieldValue('service_type') === 'mandir_connect' &&
                                    (!value || value.length === 0)
                                  ) {
                                    return Promise.reject('Upload media file');
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                            valuePropName="fileList"
                          >
                            <S3Upload
                              cdnUrl={YODA_CDN_URL}
                              preSignUrl={YODA_PRESIGN_URL}
                              min={1}
                              max={1}
                              allowedFileExtension={[AllowedFormats.MP4]}
                            />
                          </Form.Item>
                        </Row>
                      </div>
                    </Form.Item>
                    {/* </Row> */}
                  </Col>
                  <Col span={1}></Col>
                  <Col span={8}>
                    <Row justify='space-between'>
                      <Form.Item
                        label={`Temple listing video`}
                        name='info_media_video'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        valuePropName='fileList'
                        extra='Upload video'
                        rules={[
                          {
                            min: 0,
                            max: 1,
                            validator: async (rules: any, values: any) => {
                              if (values?.length < rules.min) {
                                return Promise.reject('Please upload Cover media video')
                              }
                            },
                          },
                        ]}
                      >
                        <S3Upload cdnUrl={YODA_CDN_URL} preSignUrl={YODA_PRESIGN_URL} min={0} max={1} />
                      </Form.Item>
                    </Row>
                    <Form.Item
                      label={`Temple listing images`}
                      name='info_media_image'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      valuePropName='fileList'
                      rules={[
                        {
                          min: 1,
                          max: 10,
                          validator: async (rules: any, values: any) => {
                            if (values?.length < rules.min) {
                              return Promise.reject('Please upload Temple listing Images')
                            }
                          },
                        },
                      ]}
                    >
                      <S3Upload cdnUrl={YODA_CDN_URL} preSignUrl={YODA_PRESIGN_URL} min={1} max={10} />
                    </Form.Item>
                    <Row justify='space-between' style={{ marginTop: '40px' }}>
                      <Form.Item
                        label='Position'
                        name='position'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{
                          validator: async (rule: any, value: any) => {
                            if (!value) throw new Error('Position is required.');
                          }
                        }]}
                      >
                        <InputNumber placeholder="Enter store position" min={1} />
                      </Form.Item>
                      <Form.Item
                        label={`Item images url`}
                        name='item_image_url'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        valuePropName='fileList'
                        rules={[
                          {
                            min: 1,
                            max: 1,
                            validator: async (rules: any, values: any) => {
                              if (values?.length < rules.min) {
                                return Promise.reject('Please upload item image')
                              }
                            },
                          },
                        ]}
                      >
                        <S3Upload cdnUrl={YODA_CDN_URL} preSignUrl={YODA_PRESIGN_URL} min={1} max={1} />
                      </Form.Item>
                    </Row>

                    <Row justify='space-between' style={{ width: '100%', marginTop: '10px' }}>
                      <Form.Item
                        label='City'
                        name='city'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: 'Please input city name!' }]}
                        style={{ width: '48%' }}
                      >
                        <Input placeholder='Enter city name' />
                      </Form.Item>

                      <Form.Item
                        label='State'
                        name='state'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: 'Please input state name!' }]}
                        style={{ width: '48%' }}
                      >
                        <Input placeholder='Enter state name' />
                      </Form.Item>
                    </Row>

                    <Row style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      <Form.Item
                        label={`Service Type`}
                        name='service_type'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            required: false,
                            message: 'Please select service type!',
                          },
                        ]}
                      >
                        <Select style={{ width: 200 }} placeholder='Service Type' disabled={isEditJourney}>
                          <Option value='offerings'>Offerings</Option>
                          <Option value='pooja'>Pooja</Option>
                          <Option value='promoter'>Promoter</Option>
                          <Option value='mandir_connect'>Mandir Connect</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={`Location details`}
                        name='location_details'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                          {
                            required: requiredLocationDetails,
                            message: 'Please enter location details',
                          },
                        ]}
                      >
                        <Button style={{ width: 150, background: '#1890ff', color: 'white' }} onClick={showModal}>
                          Location details
                        </Button>
                        <Modal
                          title="Location details"
                          visible={visible}
                          onOk={handleOk}
                          onCancel={handleCancel}
                          width="45vw"
                          style={{ marginTop: '-95px' }}
                        >
                          <Row style={{ display: "flex", width: "40%", justifyContent: "space-between" }}>
                            <Form.Item
                              labelCol={{ span: 18 }}
                              wrapperCol={{ span: 18 }}
                              label="Enable Location: "
                              name={['location_details', 'show_location_details']}
                              valuePropName="checked"
                            >
                              <Switch />
                            </Form.Item>
                          </Row>

                          <h3>Temple details</h3>
                          <Row>
                            <Col span={12}>
                              <Form.Item
                                name={["location_details", "temple_details", "name"]}
                                label="Name"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: requiredLocationDetails, message: 'Please input your Temple name' }]}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                name={["location_details", "temple_details", "description"]}
                                label="Description"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: requiredLocationDetails, message: 'Please input your Temple description' }]}
                              >
                                <TextArea />
                              </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: '20px' }}>
                              <Form.Item
                                name={["location_details", "temple_details", "location"]}
                                label="Location"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: requiredLocationDetails, message: 'Please input your Temple location' }]}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                label={`Temple image`}
                                name={["location_details", "temple_details", "image"]}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                  {
                                    required: requiredLocationDetails,
                                    min: 0,
                                    max: 1,
                                    validator: async (rules: any, values: any) => {
                                      if (values?.length < rules.min) {
                                        return Promise.reject('Please upload Temple Image')
                                      }
                                    },
                                  },
                                ]}
                                valuePropName='fileList'
                              >
                                <S3Upload
                                  cdnUrl={YODA_CDN_URL}
                                  preSignUrl={YODA_PRESIGN_URL}
                                  min={1}
                                  max={1}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Divider />
                          <h3>Pandit details</h3>
                          <Row>
                            <Col span={12}>
                              <Form.Item
                                name={["location_details", "pandit_details", "name"]}
                                label="Name"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: requiredLocationDetails, message: 'Please input your Pandit name' }]}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                name={["location_details", "pandit_details", "description"]}
                                label="Description"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: requiredLocationDetails, message: 'Please input your Pandit description' }]}
                              >
                                <TextArea />
                              </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: '20px' }}>
                              <Form.Item
                                name={["location_details", "pandit_details", "location"]}
                                label="Location"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: requiredLocationDetails, message: 'Please input your Pandit location' }]}
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                label={`Pandit image`}
                                name={["location_details", "pandit_details", "image"]}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[
                                  {
                                    required: requiredLocationDetails,
                                    min: 0,
                                    max: 1,
                                    validator: async (rules: any, values: any) => {
                                      if (values?.length < rules.min) {
                                        return Promise.reject('Please upload Pandit Image')
                                      }
                                    },
                                  },
                                ]}
                                valuePropName='fileList'
                              >
                                <S3Upload
                                  cdnUrl={YODA_CDN_URL}
                                  preSignUrl={YODA_PRESIGN_URL}
                                  min={1}
                                  max={1}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Divider />
                          <Form
                            form={form}
                            layout='vertical'
                            initialValues={initialValues}
                          >
                            {data ? (
                              <>
                                <Button type="default" onClick={handleRemoveClick} style={{ marginBottom: '16px', color: '#1890ff', border: '2px dotted #1890ff' }}>Remove Delivery Address</Button>
                                <h3>Delivery Pickup Address</h3>
                                <Row>
                                  <Col span={12}>
                                    <Form.Item
                                      label='Pickup Name'
                                      name={['location_details', 'delivery_pickup_address', 'name']}
                                      labelCol={{ span: 28 }}
                                      wrapperCol={{ span: 28 }}
                                      rules={[{ required: true, message: 'Enter Name' }]}
                                      style={{ marginRight: '16px' }}
                                    >
                                      <Input style={{ width: '250px' }} placeholder='Enter Name' />
                                    </Form.Item>
                                    <Form.Item
                                      label='Phone'
                                      name={['location_details', 'delivery_pickup_address', 'phone']}
                                      labelCol={{ span: 28 }}
                                      wrapperCol={{ span: 28 }}
                                      rules={[{ required: true, message: 'Enter Phone Number' }]}
                                    >
                                      <InputNumber maxLength={10} minLength={10} style={{ width: '250px' }} placeholder='Enter Phone Number' />
                                    </Form.Item>
                                  </Col>

                                  <Col span={12}>
                                    <Form.Item
                                      label='Email'
                                      name={['location_details', 'delivery_pickup_address', 'email']}
                                      labelCol={{ span: 28 }}
                                      wrapperCol={{ span: 28 }}
                                      rules={[{ required: false, message: 'Enter Email' }]}
                                    >
                                      <Input style={{ width: '250px' }} placeholder='Enter Email' />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row justify='space-between'>
                                  <Col span={22}>
                                    <Form.Item
                                      label='Address'
                                      name={['location_details', 'delivery_pickup_address', 'address']}
                                      labelCol={{ span: 28 }}
                                      wrapperCol={{ span: 28 }}
                                      rules={[{ required: true, message: 'Enter Address' },
                                      {
                                        validator: (_, value) => {
                                          if (!/\d/.test(value)) {
                                            return Promise.reject('Address should contain at least one number')
                                          } else if (value.length <= 10) {
                                            return Promise.reject('Address should be more than 10 characters')
                                          } else {
                                            return Promise.resolve();
                                          }
                                        }
                                      }
                                      ]}
                                    >
                                      <TextArea placeholder='Enter Address' style={{ width: '100%' }} />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col span={12}>
                                    <Form.Item
                                      label='Pincode'
                                      name={['location_details', 'delivery_pickup_address', 'pincode']}
                                      labelCol={{ span: 28 }}
                                      wrapperCol={{ span: 28 }}
                                      rules={[{ required: true, message: 'Enter Pincode' }]}
                                    >
                                      <InputNumber maxLength={6} minLength={6} style={{ width: '250px' }} placeholder='Enter Pincode' />
                                    </Form.Item>
                                    <Form.Item
                                      label='District'
                                      name={['location_details', 'delivery_pickup_address', 'district']}
                                      labelCol={{ span: 28 }}
                                      wrapperCol={{ span: 28 }}
                                      rules={[{ required: true, message: 'Enter District' }]}
                                    >
                                      <Input style={{ width: '250px' }} placeholder='Enter District' />
                                    </Form.Item>
                                    <Form.Item
                                      label='State'
                                      name={['location_details', 'delivery_pickup_address', 'state']}
                                      labelCol={{ span: 28 }}
                                      wrapperCol={{ span: 28 }}
                                      rules={[{ required: true, message: 'Enter State' }]}
                                    >
                                      <Input style={{ width: '250px' }} placeholder='Enter State' />
                                    </Form.Item>
                                  </Col>

                                  <Col span={12}>
                                    <Form.Item
                                      label='Landmark'
                                      name={['location_details', 'delivery_pickup_address', 'landmark']}
                                      labelCol={{ span: 30 }}
                                      wrapperCol={{ span: 30 }}
                                      rules={[{ required: true, message: 'Enter Landmark' }]}
                                    >
                                      <Input style={{ width: '250px' }} placeholder='Enter Landmark' />
                                    </Form.Item>
                                    <Form.Item
                                      label='City'
                                      name={['location_details', 'delivery_pickup_address', 'city']}
                                      labelCol={{ span: 28 }}
                                      wrapperCol={{ span: 28 }}
                                      rules={[{ required: true, message: 'Enter City' }]}
                                    >
                                      <Input style={{ width: '250px' }} placeholder='Enter City' />
                                    </Form.Item>
                                    <Form.Item
                                      label='Country'
                                      name={['location_details', 'delivery_pickup_address', 'country']}
                                      labelCol={{ span: 28 }}
                                      wrapperCol={{ span: 28 }}
                                      rules={[{ required: true, message: 'Enter Country' }]}
                                    >
                                      <Input style={{ width: '250px' }} placeholder='Enter Country' />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <>
                                <Button type="primary" onClick={handleAddClick} style={{ marginTop: '-500px', color: '#1890ff', backgroundColor: 'transparent', border: '2px dotted #1890ff', }}>Add Delivery Address</Button>
                              </>
                            )}
                          </Form>
                        </Modal>
                      </Form.Item>
                    </Row>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.service_type !== currentValues.service_type
                      }
                    >
                      {({ getFieldValue }) => (
                        getFieldValue("service_type") === "pooja" &&
                        (!slugState ? (<>
                          <Form.Item
                            label='Slug'
                            name='slug'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                              { required: true, message: 'Please input slug' },
                            ]}
                          >
                            <Input placeholder='Enter Slug' onInput={(e: any) => e.target.value = e.target.value.toLowerCase()} />
                          </Form.Item>
                        </>) : (<>
                          <Form.Item
                            label='Slug'
                            name='slug'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            validateStatus="error"
                            help="Enter valid Slug"
                            rules={[
                              { required: true, message: 'Please input slug' },
                            ]}
                          >
                            <Input placeholder='Enter Slug' onInput={(e: any) => e.target.value = e.target.value.toLowerCase()} />
                          </Form.Item>
                        </>))

                      )}
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.service_type !== currentValues.service_type
                      }
                    >
                      {({ getFieldValue }) => (
                        getFieldValue("service_type") === "pooja" &&
                        <>
                          <Form.Item
                            label='Puja Title'
                            name='pooja_title'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                              { required: true, message: 'Please Title' },
                            ]}
                          >
                            <Input placeholder='Enter Puja Title' />
                          </Form.Item>
                          <Form.Item
                            label='Puja Specificity'
                            name='pooja_specificity'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                              { required: true, message: 'Please Puja Tithi or Specificity' },
                            ]}
                          >
                            <Input placeholder='Enter Puja Tithi or Specificity' />
                          </Form.Item>
                          <Form.Item
                            label='Puja Use case'
                            name='pooja_usecase'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                              { required: true, message: 'Please Puja Use case' },
                            ]}
                          >
                            <Input placeholder='Enter Puja Use case' />
                          </Form.Item>
                        </>
                      )}
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.service_type !== currentValues.service_type
                      }
                    >
                      {({ getFieldValue }) => (
                        getFieldValue("service_type") === "pooja" &&
                        <Form.Item
                          label={`Astro puja list`}
                          name='astro_pooja_list'
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[
                            {
                              required: false,
                              message: 'Please select Astro puja list!',
                            },
                          ]}
                        >
                          <Select placeholder='Astro Puja list' mode="multiple">
                            <Option value='KAL_SARPA'>KAL SARPA</Option>
                            <Option value='PITRI_DOSHA'>PITRI DOSHA</Option>
                            <Option value='NAKSHATRA_PUJA'>NAKSHATRA PUJA</Option>
                            <Option value='WELCOME_PUJA'>WELCOME PUJA</Option>
                            <Option value='MANGAL_DOSHA'>MANGAL DOSHA</Option>
                            <Option value='GURU_CHANDAL_DOSHA'>GURU CHANDAL DOSHA</Option>
                            <Option value='GRAHAN_DOSHA'>GRAHAN DOSHA</Option>
                            <Option value='GANDMOOL_DOSHA'>GANDMOOL DOSHA</Option>
                            <Option value='SHANI_DOSHA'>SHANI DOSHA</Option>
                            <Option value='RAHU_DOSHA'>RAHU DOSHA</Option>
                            <Option value='NAVGRAHA_DOSHA'>NAVGRAHA DOSHA</Option>
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      label={`Tags`}
                      name='tags'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          required: false,
                          message: 'Please select Tags!',
                        },
                      ]}
                    >
                      <Select placeholder='Tags' mode="multiple">
                        {tags?.[acceptLanguage]?.map((tag: string) => (
                          <Option key={tag} value={tag}>{tag}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Row>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label="Show Reorder Card(L2)"
                        name='show_reorder_card_l2'
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                      <Form.Item
                        label={`Store type`}
                        name='store_type'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ width: '60%', marginLeft: '20px' }}
                        rules={[
                          {
                            required: false,
                            message: 'Please select Store type!',
                          },
                        ]}
                      >
                        <Select placeholder='Store type' allowClear>
                          <Option value='regular'>Regular</Option>
                          <Option value='mixed'>Mixed</Option>
                          <Option value='subscription'>Subscription</Option>
                          {/* <Option value='preBooking'>PreBooking</Option>
                          <Option value='preBookingWeekday'>PreBooking Weekday</Option> */}
                        </Select>
                      </Form.Item>
                    </Row>
                    <Col span={24}>
                      <Form.Item
                        label='Temple Media'
                        style={{ marginTop: '10px' }}
                      >
                        <div style={{
                          border: '1px solid #e0dcdc',
                          marginRight: '0px',
                          marginBottom: '3px',
                          padding: '10px',
                        }} title='Temple Media'>
                          <Row justify="space-between" style={{ width: '100%' }}>
                            <Form.Item
                              label='Media type'
                              name={['temple_media', 'media_type']}
                              labelCol={{ span: 28 }}
                              wrapperCol={{ span: 28 }}
                              style={{ width: '50%' }}
                              rules={[
                                {
                                  required: false,
                                  message: 'Enter media type',
                                },
                              ]}
                            >
                              <Select placeholder="Please select media type">
                                <Option value={'video'} key={'video'}>
                                  Video
                                </Option>
                                <Option value={'image'} key={'image'}>
                                  Image
                                </Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              label={`Media`}
                              name={['temple_media', 'media_url']}
                              labelCol={{ span: 28 }}
                              rules={[
                                {
                                  required: false,
                                  min: 0,
                                  max: 1,
                                  validator: async (rules: any, values: any) => {
                                    if (values?.length < rules.min) {
                                      return Promise.reject('Please upload media');
                                    }
                                  },
                                },
                              ]}
                              valuePropName="fileList"
                            >
                              <S3Upload
                                cdnUrl={YODA_CDN_URL}
                                preSignUrl={YODA_PRESIGN_URL}
                                min={1}
                                max={1}
                                allowedFileExtension={[AllowedFormats.MP4, AllowedFormats.PNG, AllowedFormats.JPEG, AllowedFormats.JPG]}
                              />
                            </Form.Item>
                          </Row>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label='Store Code'
                        name='store_code'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: false, message: 'Please input store code!' }]}
                        style={{ width: '48%' }}
                      >
                        <Input placeholder='Enter store code' min={0} maxLength={5} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item label="Platform Visibility" style={{ width: '70%' }}>
                        <div style={{
                          border: '1px solid #e0dcdc',
                          marginRight: '0px',
                          marginBottom: '3px',
                          padding: '10px',
                        }} title='Platform Visibility'>
                          <Row>
                            <Col span={24}>
                              <Form.Item shouldUpdate noStyle>
                                {({ getFieldValue }) => {
                                  const checked = getFieldValue('android') && getFieldValue('ios') && getFieldValue('web');
                                  return (
                                    <Checkbox
                                      checked={checked}
                                      onChange={handleCheckAllPlatforms}
                                      style={{ marginRight: '8px' }}
                                    >
                                      All
                                    </Checkbox>
                                  );
                                }}
                              </Form.Item>
                            </Col>
                          </Row>
                          <div style={{
                            border: '1px solid #e0dcdc',
                            padding: '10px',
                            marginTop: '10px'
                          }}>
                            <Row>
                              <Col span={8}>
                                <Form.Item name="android" valuePropName="checked" noStyle>
                                  <Checkbox onChange={handleCheckboxChange} style={{ marginRight: '8px', marginBottom: '4px' }}>
                                    Android
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name="ios" valuePropName="checked" noStyle>
                                  <Checkbox onChange={handleCheckboxChange} style={{ marginRight: '8px', marginBottom: '4px' }}>
                                    iOS
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name="web" valuePropName="checked" noStyle>
                                  <Checkbox onChange={handleCheckboxChange} style={{ marginRight: '8px', marginBottom: '4px' }}>
                                    Web
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={6}>
                    <Row>
                      <Col span={13}>
                        <Form.Item
                          label={`Closed Cover Media type`}
                          name='closedCoverMediaType'
                          labelCol={{ span: 20 }}
                          wrapperCol={{ span: 24 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please select closed cover media type!',
                            },
                          ]}
                        >
                          <Select style={{ width: 100 }} placeholder='Type'>
                            <Option value='lottie'>Lottie</Option>
                            <Option value='image'>Image</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          label={`Closed Cover Media`}
                          name='closed_media_url'
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[
                            {
                              min: 1,
                              max: 1,
                              validator: async (rules: any, values: any) => {
                                if (values?.length < rules.min) {
                                  return Promise.reject('Please upload Closed Cover media Images')
                                }
                              },
                            },
                          ]}
                          valuePropName='fileList'
                        >
                          <S3Upload
                            cdnUrl={YODA_CDN_URL}
                            preSignUrl={YODA_PRESIGN_URL}
                            min={1}
                            max={1}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={`Closed Temple listing images`}
                          name='closed_info_media_image'
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          valuePropName='fileList'
                          extra='Upload images'
                          rules={[
                            {
                              min: 1,
                              max: 4,
                              validator: async (rules: any, values: any) => {
                                if (values?.length < rules.min) {
                                  return Promise.reject('Please upload closed Temple listing Images')
                                }
                              },
                            },
                          ]}
                        >
                          <S3Upload cdnUrl={YODA_CDN_URL} preSignUrl={YODA_PRESIGN_URL} min={1} max={4} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label={`Closed Temple listing video`}
                          name='closed_info_media_video'
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          valuePropName='fileList'
                          extra='Upload video'
                          rules={[
                            {
                              min: 0,
                              max: 1,
                              validator: async (rules: any, values: any) => {
                                if (values?.length < rules.min) {
                                  return Promise.reject('Please upload Closed Cover media video')
                                }
                              },
                            },
                          ]}
                        >
                          <S3Upload cdnUrl={YODA_CDN_URL} preSignUrl={YODA_PRESIGN_URL} min={0} max={1} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label={`Certificate Image`}
                          name='certificate_image'
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[
                            {
                              min: 1,
                              max: 1,
                              validator: async (rules: any, values: any) => {
                                if (values?.length < rules.min) {
                                  return Promise.reject('Please upload certificate Image')
                                }
                              },
                            },
                          ]}
                          valuePropName='fileList'
                        >
                          <S3Upload
                            cdnUrl={YODA_CDN_URL}
                            preSignUrl={YODA_PRESIGN_URL}
                            allowedFileExtension={[AllowedFormats.JPEG, AllowedFormats.PNG]}
                            min={1}
                            max={1}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Row>
                          <Col span={10}>

                            <Form.Item
                              label='Select WhatsApp Media Type'
                              name='whatsappMediaType'
                              rules={[{ required: false, message: 'Please select media type!' }]}
                            >
                              <Radio.Group>
                                <Radio value={"video"}>Video</Radio>
                                <Radio value={"image"}>Image</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          <Col span={14}>

                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) =>
                                prevValues.whatsappMediaType !== currentValues.whatsappMediaType
                              }
                            >
                              {({ getFieldValue }) => (
                                getFieldValue("whatsappMediaType") === "video" && <Row>
                                  <Col span={12}>
                                    <Form.Item
                                      label='WhatsApp Media URL'
                                      name='whatsapp_share_media'
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      valuePropName='fileList'
                                      rules={[
                                        {
                                          min: 0,
                                          max: 1,
                                          validator: async (rules: any, values: any) => {
                                            if (values?.length < rules.min) {
                                              return Promise.reject('Please upload MP4 media url')
                                            }
                                          },
                                        },
                                      ]}
                                    >
                                      <S3Upload
                                        cdnUrl={YODA_CDN_URL}
                                        preSignUrl={YODA_PRESIGN_URL}
                                        allowedFileExtension={[AllowedFormats.MP4]}
                                        min={0}
                                        max={1}
                                      />
                                    </Form.Item>
                                  </Col>

                                </Row>

                              )}
                            </Form.Item>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) =>
                                prevValues.whatsappMediaType !== currentValues.whatsappMediaType
                              }
                            >
                              {({ getFieldValue }) => (
                                getFieldValue("whatsappMediaType") === "image" && <Row>
                                  <Col span={12}>
                                    <Form.Item
                                      label='WhatsApp Media URL'
                                      name='whatsapp_share_media'
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      valuePropName='fileList'
                                      rules={[
                                        {
                                          min: 0,
                                          max: 1,
                                          validator: async (rules: any, values: any) => {
                                            if (values?.length < rules.min) {
                                              return Promise.reject('Please upload image ')
                                            }
                                          },
                                        },
                                      ]}
                                    >
                                      <S3Upload
                                        cdnUrl={YODA_CDN_URL}
                                        preSignUrl={YODA_PRESIGN_URL}
                                        allowedFileExtension={[AllowedFormats.PNG, AllowedFormats.JPEG,]}
                                        min={0}
                                        max={1}
                                      />
                                    </Form.Item>

                                  </Col>
                                </Row>

                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Button
                            icon={<CopyOutlined />}
                            type='primary'
                            onClick={handleCloneModal}
                            style={{ marginTop: '22px', width: '40%' }}
                          >
                            Clone
                          </Button>
                        </Row>

                        <Row style={{ marginTop: '30px', marginBottom: '10px' }}>
                          <Form.Item
                            label={`Store God Configuration`}
                            name='store_god_configuration'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Button style={{ width: '100%', background: '#1890ff', color: 'white' }} onClick={showModalGodDetails}>
                              Store God Config
                            </Button>
                            <Modal
                              title="Store God Configuration"
                              visible={visibleGodDetails}
                              onOk={handleGodDetailsOk}
                              onCancel={handleGodDetailsCancel}
                              width="45vw"
                              style={{ marginTop: '-95px' }}
                            >
                              <Form.Item
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                label="Show Reorder Card(L1)"
                                name='show_reorder_card_l1'
                                valuePropName="checked"
                              >
                                <Switch />
                              </Form.Item>
                              <h2>God Details</h2>
                              <Row gutter={[16, 16]}>
                                <Col span={8} style={{ marginLeft: 16 }}>
                                  <Form.Item
                                    label='God Name'
                                    name={['god_details', 'god_name']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    shouldUpdate={(prevValues, currentValues) =>
                                      prevValues?.god_details?.god_name !== currentValues?.god_details?.god_name ||
                                      prevValues?.show_reorder_card_l1 !== currentValues?.show_reorder_card_l1
                                    }
                                    rules={[
                                      ({ getFieldValue }) => ({
                                        required: visibleGodDetails && getFieldValue('show_reorder_card_l1'),
                                        message: 'Please type a god name',
                                      })
                                    ]}
                                  >
                                    <Input placeholder='Enter god name' />
                                  </Form.Item>
                                </Col>
                                <Col span={8} style={{ marginTop: '-20px' }}>
                                  <Form.Item
                                    label="God Image"
                                    name={['god_details', 'god_image']}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    shouldUpdate={(prevValues, currentValues) =>
                                      prevValues?.show_reorder_card_l1 !== currentValues?.show_reorder_card_l1
                                    }
                                    rules={[
                                      ({ getFieldValue }) => ({
                                        required: visibleGodDetails && getFieldValue('show_reorder_card_l1'),
                                        message: 'Please select a god image',
                                      }),
                                    ]}
                                    valuePropName='fileList'
                                  >
                                    <S3Upload
                                      cdnUrl={YODA_CDN_URL}
                                      preSignUrl={YODA_PRESIGN_URL}
                                      min={1}
                                      max={1}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Modal>
                          </Form.Item>
                        </Row>
                        <Row>
                          <Form.Item
                            label='God Weekday'
                            name='weekday'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Select placeholder='Weekday' allowClear >
                              {weekdays.map(day => (
                                <Option key={day.value} value={day.value}>{day.name}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Row>
                        <Row>
                          <Form.Item
                            name='process_date'
                            label='Process Date'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[
                              {
                                required: false,
                              },
                            ]}
                          >
                            <DatePicker disabledDate={disabledDate} showTime />
                          </Form.Item>
                        </Row>
                        <Row>
                          <Form.Item
                            label='Temple Exploration'
                          >
                            <div style={{
                              border: '1px solid #e0dcdc',
                              marginRight: '0px',
                              marginBottom: '3px',
                              padding: '10px',
                            }} title='Temple Exploration'>
                              <Row justify="space-between" style={{ width: '100%', marginTop: '10px' }}>
                                <Form.Item
                                  label='Position'
                                  name={['temple_exploration', 'position']}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  style={{ marginRight: '130px', marginLeft: '10px' }}
                                >
                                  <InputNumber min={0} style={{ width: '100%' }} />
                                </Form.Item>

                                <Form.Item
                                  label="Media type"
                                  name={['temple_exploration', 'media', 'media_type']}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Please select media type!',
                                    },
                                  ]}
                                  style={{ marginLeft: '10px' }}
                                >
                                  <Select placeholder='Type' style={{ width: '170px' }}>
                                    <Option value='lottie'>GIF</Option>
                                    <Option value='image'>Image</Option>
                                  </Select>
                                </Form.Item>

                                <Form.Item
                                  name={['temple_exploration', 'media', 'media_url']}
                                  label="Media url"
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  rules={[
                                    {
                                      min: 0,
                                      max: 1,
                                      validator: async (rules: any, values: any) => {
                                        if (values?.length < rules.min) {
                                          return Promise.reject('Please upload media')
                                        }
                                      },
                                    },
                                  ]}
                                  valuePropName='fileList'
                                  style={{ marginLeft: '-25px' }}
                                >
                                  <S3Upload
                                    cdnUrl={YODA_CDN_URL}
                                    preSignUrl={YODA_PRESIGN_URL}
                                    min={1}
                                    max={1}
                                  />
                                </Form.Item>
                              </Row>
                            </div>
                          </Form.Item>
                        </Row>
                        <Row>
                          <Row style={{ marginTop: '30px', marginBottom: '10px' }}>
                            <Form.Item
                              label={`Subscription Details`}
                              name='subscription_details'
                              labelCol={{ span: 24 }}
                              wrapperCol={{ span: 24 }}
                            >
                              <Button style={{ width: '100%', background: '#1890ff', color: 'white' }} onClick={showModalSubscriptionDetails}>
                                Subscription Details
                              </Button>
                              <Modal
                                title="Subscription Details"
                                visible={visibleSubscriptionDetails}
                                onOk={handleSubscriptionDetailsOk}
                                onCancel={handleSubscriptionDetailsCancel}
                                width="45vw"
                                centered
                              >
                                <Form.Item
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  label="Recommend Subscription"
                                  name={['subscription_details', 'recommend_subscription']}
                                  valuePropName="checked"
                                >
                                  <Switch />
                                </Form.Item>
                                <h2>Subscription Config</h2>
                                <Row gutter={[16, 16]}>
                                  <Col span={8}>
                                    <Form.Item
                                      label='Interval'
                                      name={['subscription_details', 'subscription_config', 'interval']}
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      shouldUpdate
                                      rules={[
                                        ({ getFieldValue }) => ({
                                          required: visibleSubscriptionDetails && getFieldValue(['subscription_details', 'recommend_subscription']),
                                          message: 'Please enter interval',
                                        })
                                      ]}
                                    >
                                      <Select placeholder='Interval' allowClear>
                                        <Option value='weekly'>Weekly</Option>
                                        <Option value='monthly'>Monthly</Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={8} >
                                    <Form.Item
                                      shouldUpdate
                                    >
                                      {({ getFieldValue }) => {
                                        const interval = getFieldValue(['subscription_details', 'subscription_config', 'interval']);
                                        if (interval === 'weekly') {
                                          return (
                                            <Form.Item
                                              label="Day of the Week"
                                              name={['subscription_details', 'subscription_config', 'weekday']}
                                              labelCol={{ span: 24 }}
                                              wrapperCol={{ span: 24 }}
                                              rules={[{ required: true, message: 'Please select a day' }]}
                                            >
                                              <Select placeholder='Weekday' allowClear >
                                                {weekdays?.map(day => (
                                                  <Option key={day?.value} value={day?.value}>{day?.name}</Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                          );
                                        }
                                        if (interval === 'monthly') {
                                          return (
                                            <Form.Item
                                              label="Day of the Month"
                                              name={['subscription_details', 'subscription_config', 'date']}
                                              labelCol={{ span: 24 }}
                                              wrapperCol={{ span: 24 }}
                                              rules={[{ required: true, message: 'Please enter a date' }]}
                                            >
                                              <DatePicker disabledDate={disabledDate} showTime />
                                            </Form.Item>
                                          );
                                        }
                                        return null;
                                      }}
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <h2>Subscription Discount Details</h2>

                                <Form.Item
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                  label="Enable Discount"
                                  name={['subscription_details', 'subscription_discount', 'is_subscription_discount']}
                                  valuePropName="checked"
                                >
                                  <Switch />
                                </Form.Item>

                                <Form.Item
                                  shouldUpdate={(prevValues, currentValues) =>
                                    prevValues?.subscription_details?.subscription_discount?.is_subscription_discount !== currentValues?.subscription_discount?.subscription_discount?.is_subscription_discount
                                  }
                                >
                                  {({ getFieldValue }) => {
                                    const isDiscountEnabled = getFieldValue(['subscription_details', 'subscription_discount', 'is_subscription_discount']);
                                    if (isDiscountEnabled) {
                                      return (
                                        <Row gutter={[16, 16]}>
                                          <Col span={8}>
                                            <Form.Item
                                              label="Discount Type"
                                              name={['subscription_details', 'subscription_discount', 'type']}
                                              labelCol={{ span: 24 }}
                                              wrapperCol={{ span: 24 }}
                                              rules={[{ required: true, message: 'Please select a discount type' }]}
                                            >
                                              <Select placeholder="Select discount type" allowClear>
                                                <Option value="percentage">Percentage</Option>
                                                <Option value="absolute">Absolute</Option>
                                              </Select>
                                            </Form.Item>
                                          </Col>
                                          <Col span={10}>
                                            <Form.Item
                                              label="Discount Value"
                                              name={['subscription_details', 'subscription_discount', 'value']}
                                              labelCol={{ span: 24 }}
                                              wrapperCol={{ span: 24 }}
                                              rules={[{ required: true, message: 'Please enter the discount value' }]}
                                              style={{ width: '100%' }}
                                            >
                                              <InputNumber placeholder="Enter value" min={0} style={{ width: '80%' }} />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    return null;
                                  }}
                                </Form.Item>

                              </Modal>
                            </Form.Item>
                          </Row>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col >
              <Form.Item
                label='Payment Success Page Media'
              >
                <Row>
                  <Form.List name={['txn_success_media']}>
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <Row style={{ width: '100%' }}>
                            {fields.map((field: any, index: number) => {
                              return (
                                <Col span={4} key={field.key} style={{ paddingRight: '12px', paddingTop: '10px' }}>
                                  <div
                                    style={{
                                      border: '1px solid #e0dcdc',
                                      marginBottom: '16px',
                                      padding: '10px',
                                    }}
                                  >
                                    <Row justify="space-between" style={{ width: '100%' }}>
                                      <Form.Item
                                        label={`Media type ${index + 1}`}
                                        name={[field.name, 'media_type']}
                                        labelCol={{ span: 18 }}
                                        wrapperCol={{ span: 18 }}
                                        style={{ flexBasis: '80%', width: '60%' }}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Enter media type',
                                          },
                                        ]}
                                      >
                                        <Select placeholder="Please Select Media type">
                                          <Option value={'video'} key={'video'}>
                                            video
                                          </Option>
                                        </Select>
                                      </Form.Item>

                                      <Button
                                        onClick={() => {
                                          remove(field.name);
                                        }}
                                        type="ghost"
                                        icon={<DeleteOutlined />}
                                        size="middle"
                                        danger
                                        style={{ marginTop: '30px' }}
                                      ></Button>

                                      <Form.Item
                                        label={`Media`}
                                        name={[field.name, 'media_url']}
                                        labelCol={{ span: 28 }}
                                        rules={[
                                          {
                                            required: true,
                                            min: 0,
                                            max: 1,
                                            validator: async (rules: any, values: any) => {
                                              if (values?.length < rules.min) {
                                                return Promise.reject('Please upload Media');
                                              }
                                            },
                                          },
                                        ]}
                                        valuePropName="fileList"
                                      >
                                        <S3Upload
                                          cdnUrl={YODA_CDN_URL}
                                          preSignUrl={YODA_PRESIGN_URL}
                                          min={1}
                                          max={1}
                                          allowedFileExtension={[AllowedFormats.MP4]}
                                        />
                                      </Form.Item>

                                    </Row>

                                    {/* <Form.Item
                                  label={`Deeplink ${index + 1}`}
                                  name={[field.name, 'deeplink']}
                                  labelCol={{ span: 28 }}
                                  wrapperCol={{ span: 28 }}
                                  style={{ flexBasis: '50%' }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Enter Deeplink',
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter Deeplink" />
                                </Form.Item> */}
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>

                          <Col span={24} style={{ paddingTop: '5px' }}>
                            <Row justify="start">
                              <Col span={4}>
                                <Button
                                  onClick={() => {
                                    add();
                                  }}
                                  type="ghost"
                                  icon={<PlusCircleOutlined />}
                                  size="middle"
                                >
                                  Add Success media
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </>
                      );
                    }}
                  </Form.List>
                </Row>
              </Form.Item>

            </Form >
            <Col span={24}>
              <LabelRightAlign>
                <Space>
                  <Button type='primary' onClick={() => navigate("/monetisation/offerings/temples")}>
                    Cancel
                  </Button>
                  {currentStep > 0 && (
                    <Button type='primary' onClick={previousHandler}>
                      Previous
                    </Button>
                  )}
                  {isEditJourney && currentStep !== 1 && (
                    <Button type='primary' onClick={submitFormHandler}>
                      Update
                    </Button>
                  )}
                  {currentStep < 3 && currentStep !== 1 && !isEditJourney ? (
                    <Button type='primary' onClick={submitFormHandler}>
                      Submit
                    </Button>
                  ) : (
                    currentStep !== 2 && (
                      <Button type='primary' onClick={nextHandler}>
                        Next
                      </Button>
                    )
                  )}
                </Space>
              </LabelRightAlign>
            </Col>
          </>
      }
    </Row >
  )
}
export default TempleCreation
